import { createStore, combineReducers, compose, Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { translateService } from './services/TranslateService';
import {IQualification, ISmallAdContent, User} from './interfaces/User';
import { TOS } from './interfaces/TOS';
import SecureLS from 'secure-ls';
import { ResponseModalStruct } from './interfaces/ModalStructs';
import { Reservation } from './interfaces/Reservation';
import { PaymentRedirectResponse } from './interfaces/PaymentForm';
import ApiService from './services/api/ApiService';
import * as Sentry from "@sentry/react";
import { IParkingInfo } from './interfaces/IParkingInfo';
import { ISearchParameters, ICoupon, IVehicleSearch } from './interfaces/VehicleSearch';
import { Vehicle } from './interfaces/Vehicle';
import { IBalance } from './interfaces/User'

// structure of entire Redux store
export interface GlobalStore {
    chauffeurReducer: {
        dbToken: string | undefined;
        jwt: string | undefined;
        deviceLanguage: string;
        API_VERSION: string;
        browser: any;
        version: string;
        API_URL: string;
        user: User | undefined;
        reservation: Reservation | undefined;
        userPhoto: string | undefined;
        licensePhoto: string | undefined;
        termsOfService: TOS[];
        responseModal: ResponseModalStruct | undefined;
        apiMessage: string | undefined;
        phase: string | undefined;
        subPhase: string;
        newPhotoQueued: boolean;
        deferredPrompt: any;
        pcSkip: boolean;
        driverLogin: boolean;
        allReservations: Reservation[];
        paymentResponse: PaymentRedirectResponse | undefined;
        apiService: ApiService | undefined;
        parkingInfo: IParkingInfo | undefined;
        landbotRef: HTMLDivElement | undefined;
        selectedCar: IVehicleSearch | undefined,
        searchParameters: ISearchParameters | undefined,
        coupon: ICoupon | undefined,
        showMinileasing: boolean,
        showLandbotButton: boolean,
        rrmHash: string | undefined;
        unpaidBalance: IBalance | undefined;
        qualification: IQualification | undefined;
        smallAdContent: ISmallAdContent | undefined;
        landBotNotAllowed: boolean;
    };
}

// each of the actions we use
type ActionApiUrl = {
    type: 'SET_API_URL';
    payload: string;
};
type ActionApiVer = {
    type: 'SET_API_VERSION';
    payload: string;
};
type ActionLang = {
    type: 'SET_LANG';
    payload: string;
};
type ActionToken = {
    type: 'SET_TOKEN';
    payload: {
        token: string;
        jwt: string;
        driverLogin: boolean;
    };
};

type ActionRRMHash = {
    type: 'SET_RRM_HASH';
    payload: string;
}

type ActionClearToken = {
    type: 'CLEAR_TOKEN';
};
type ActionGetToken = {
    type: 'GET_TOKEN';
};
type ActionBrowser = {
    type: 'SET_BROWSER';
    payload: any;
};
type ActionVersion = {
    type: 'SET_VERSION';
    payload: string;
};
type ActionUser = {
    type: 'SET_USER';
    payload: User;
};
type ActionUserQualification = {
    type: 'SET_USER_QUALIFICATION';
    payload: IQualification;
};
type ActionSetReservation = {
    type: 'SET_RESERVATION';
    payload: Reservation;
};
type ActionUserPhoto = {
    type: 'SET_USER_PHOTO';
    payload: string;
};
type ActionLicensePhoto = {
    type: 'SET_LICENSE_PHOTO';
    payload: string;
};
type ActionTOS = {
    type: 'SET_TOS';
    payload: TOS[];
};
type ActionResModal = {
    type: 'SET_RESPONSE_MODAL';
    payload: any;
};
type ActionApiMessage = {
    type: 'SET_API_MESSAGE';
    payload: string | undefined;
};
type ActionPhase = {
    type: 'SET_PHASE';
    payload: string | undefined;
};
type ActionSubPhase = {
    type: 'SET_SUBPHASE';
    payload: string;
};
type ActionNewPhotoQueued = {
    type: 'SET_NEWPHOTO_QUEUED';
    payload: boolean;
};
type ActionDeferredPrompt = {
    type: 'SET_DEFERRED_PROMPT';
    payload: boolean;
};
type ActionPcSkip = {
    type: 'SET_PC_SKIP';
    payload: boolean;
};
type ActionDriverLogin = {
    type: 'SET_DRIVER_LOGIN';
};
type ActionAllReservations = {
    type: 'SET_ALL_RESERVATIONS';
    payload: Reservation[];
};
type ActionSetBoxCode = {
    type: 'SET_BOXCODE';
    payload: any;
};
type ActionSetKeyLocation = {
    type: 'SET_KEY_LOCATION';
    payload: any;
};
type ActionSetPaymentRedirect = {
    type: 'SET_PAY_REDIRECT';
    payload: PaymentRedirectResponse;
};
type ActionSetApiService = {
    type: 'SET_API_SERVICE';
    payload: ApiService;
};
type ActionSetParkingInfo = {
    type: 'SET_PARKING_INFO';
    payload: IParkingInfo;
};

type ActionSetLandbotRef = {
    type: 'SET_LANDBOT_REF';
    payload: HTMLDivElement;
};

type ActionSetSelectedCar = {
    type: 'SET_SELECTED_CAR';
    payload: Vehicle;
};

type ActionSetSearchParameters = {
    type: 'SET_SEARCH_PARAMETERS';
    payload: ISearchParameters;
};

type ActionSetCoupon = {
    type: 'SET_DISCOUNT_COUPON';
    payload: ICoupon;
};

type ActionSetClearSelections = {
    type: 'CLEAR_SELECTIONS';
    payload: any;
};

type ActionShowMinileasing = {
    type: 'SHOW_MINILEASING';
    payload: any;
};

type ActionShowLandbotButton = {
    type: 'SHOW_LANDBOT_BUTTON';
    payload: any;
};

type ActionSetUnpaidBalance = {
    type: 'SET_UNPAID_BALANCE';
    payload: IBalance | undefined;
}

type ActionSetSmallAdContent = {
    type: 'SET_SMALL_AD_CONTENT';
    payload: ISmallAdContent | undefined;
}

type ActionSetLandBotNotAllowed = {
    type: 'SET_LANDBOT_NOT_ALLOWED';
    payload: boolean;
}

// all the Actions merged, and exported as Dispatch
type Action =
    | ActionApiUrl
    | ActionApiVer
    | ActionLang
    | ActionToken
    | ActionGetToken
    | ActionClearToken
    | ActionBrowser
    | ActionVersion
    | ActionUser
    | ActionUserQualification
    | ActionSetReservation
    | ActionUserPhoto
    | ActionLicensePhoto
    | ActionTOS
    | ActionResModal
    | ActionApiMessage
    | ActionPhase
    | ActionNewPhotoQueued
    | ActionDeferredPrompt
    | ActionPcSkip
    | ActionDriverLogin
    | ActionAllReservations
    | ActionSubPhase
    | ActionSetBoxCode
    | ActionSetKeyLocation
    | ActionSetPaymentRedirect
    | ActionSetApiService
    | ActionSetParkingInfo
    | ActionSetLandbotRef
    | ActionSetSelectedCar
    | ActionSetSearchParameters
    | ActionSetCoupon
    | ActionSetClearSelections
    | ActionShowMinileasing
    | ActionShowLandbotButton
    | ActionRRMHash
    | ActionSetUnpaidBalance
    | ActionSetSmallAdContent
    | ActionSetLandBotNotAllowed;

export type Dispatch = (action: Action) => void;

const aesC = new SecureLS({ encodingType: 'aes', encryptionSecret: process.env.REACT_APP_TSEC });

// initial state of the store
const INITIAL_STATE: GlobalStore = {
    chauffeurReducer: {
        dbToken: '',
        jwt: '',
        deviceLanguage: sessionStorage.getItem('language') ?? 'fi',
        API_VERSION: '2',
        browser: {},
        version: '',
        API_URL: '',
        user: undefined,
        reservation: undefined,
        userPhoto: undefined,
        licensePhoto: undefined,
        termsOfService: [],
        responseModal: undefined,
        apiMessage: undefined,
        phase: undefined,
        subPhase: '',
        newPhotoQueued: false,
        deferredPrompt: undefined,
        pcSkip: false,
        driverLogin: false,
        allReservations: [],
        paymentResponse: undefined,
        apiService: undefined,
        parkingInfo: undefined,
        landbotRef: undefined,
        selectedCar: undefined,
        searchParameters: undefined,
        coupon: undefined,
        showMinileasing: !localStorage.getItem("C2-BTL"),
        showLandbotButton: true,
        rrmHash: '',
        unpaidBalance: undefined,
        qualification: undefined,
        smallAdContent: undefined,
        landBotNotAllowed: false,
    },
};

const mainReducer: Reducer = (state = INITIAL_STATE.chauffeurReducer, action: Action) => {
    switch (action.type) {
        case 'SET_API_VERSION':
            return {
                ...state,
                API_VERSION: action.payload,
            };

        case 'SET_API_URL':
            return {
                ...state,
                API_URL: action.payload,
            };

        case 'SET_LANG':
            console.log("Setting language to: ", action.payload);
            // is this a side-effect??
            translateService.setLocale(action.payload);
            return {
                ...state,
                deviceLanguage: action.payload,
            };

        case 'CLEAR_TOKEN':
            aesC.remove('C2-XT');
            aesC.remove('C2-STORE');
            aesC.remove('C2-RMA');
            return {
                ...state,
                dbToken: INITIAL_STATE.chauffeurReducer.dbToken,
                jwt: INITIAL_STATE.chauffeurReducer.jwt,
                user: INITIAL_STATE.chauffeurReducer.user,
                userPhoto: INITIAL_STATE.chauffeurReducer.userPhoto,
                licensePhoto: INITIAL_STATE.chauffeurReducer.licensePhoto,
                rrmHash: INITIAL_STATE.chauffeurReducer.rrmHash,
                unpaidBalance: INITIAL_STATE.chauffeurReducer.unpaidBalance,
                smallAdContent: INITIAL_STATE.chauffeurReducer.smallAdContent
            };

        case 'SET_TOKEN':
            aesC.set('C2-XT', action.payload.token);
            aesC.set('C2-STORE', action.payload.jwt);
            localStorage.setItem('C2-DL', action.payload.driverLogin === true ? '1' : '0');
            return {
                ...state,
                dbToken: action.payload.token,
                jwt: action.payload.jwt,
                driverLogin: action.payload.driverLogin,
            };

        case 'GET_TOKEN': {
            try {
                return {
                    ...state,
                    dbToken: aesC.get('C2-XT'),
                    jwt: aesC.get('C2-STORE'),
                    driverLogin: localStorage.getItem('C2-DL') === '1' ? true : false,
                    rrmHash: aesC.get('C2-RMA'),
                };
            } catch (e) {
                console.log(e);
                aesC.remove('C2-XT');
                aesC.remove('C2-STORE');
                aesC.remove('C2-RMA');
                localStorage.removeItem('C2-DL');
                return {
                    ...state,
                    dbToken: '',
                    jwt: '',
                    driverLogin: false,
                    rrmHash: '',
                    unpaidBalance: undefined,
                    smallAdContent: undefined
                }
            }
        }

        case 'SET_RRM_HASH':
            aesC.set('C2-RMA', action.payload);
            return {
                ...state,
                rrmHash: action.payload,
            };

        case 'SET_BROWSER':
            return {
                ...state,
                browser: action.payload,
            };

        case 'SET_VERSION':
            return {
                ...state,
                version: action.payload,
            };

        case 'SET_USER':
            // is this a side-effect??
            let selectedLang = sessionStorage.getItem('language') ?? undefined;
            if (action.payload.language && !selectedLang) {
                translateService.setLocale(action.payload.language);
            }
            return {
                ...state,
                deviceLanguage: !selectedLang ? action.payload.language : selectedLang,
                user: action.payload,
            };

        case 'SET_USER_QUALIFICATION':
            // is this a side-effect??
            return {
                ...state,
                qualification: action.payload,
            };

        case 'SET_RESERVATION':
            return {
                ...state,
                reservation: action.payload,
            };

        case 'SET_BOXCODE':
            return {
                ...state,
                reservation: {
                    ...state.reservation,
                    vehicle: {
                        ...state.reservation?.vehicle,
                        boxCode: action.payload.code,
                        boxLocation: action.payload.location
                    }
                }
            }

        case 'SET_KEY_LOCATION':
            return {
                ...state,
                reservation: {
                    ...state.reservation,
                    vehicle: {
                        ...state.reservation?.vehicle,
                        keyLocation: action.payload
                    }
                }
            }

        case 'SET_ALL_RESERVATIONS':
            return {
                ...state,
                allReservations: action.payload,
            };

        case 'SET_USER_PHOTO':
            return {
                ...state,
                userPhoto: action.payload,
            };

        case 'SET_LICENSE_PHOTO':
            return {
                ...state,
                licensePhoto: action.payload,
            };

        case 'SET_TOS':
            return {
                ...state,
                termsOfService: action.payload,
            };

        case 'SET_RESPONSE_MODAL':
            if (action.payload) {
                return {
                    ...state,
                    responseModal: action.payload,
                };
            } else {
                return {
                    ...state,
                    responseModal: undefined,
                };
            }

        case 'SET_API_MESSAGE':
            if (action.payload) {
                return {
                    ...state,
                    apiMessage: action.payload,
                };
            } else {
                return {
                    ...state,
                    apiMessage: undefined,
                };
            }

        case 'SET_PHASE':
            if (action.payload !== 'Briefing' && action.payload !== 'Debriefing') {
                return {
                    ...state,
                    phase: action.payload,
                    subPhase: ''
                };
            } else {
                return {
                    ...state,
                    phase: action.payload,
                };
            }

        case 'SET_SUBPHASE':
            return {
                ...state,
                subPhase: action.payload,
            };

        case 'SET_NEWPHOTO_QUEUED':
            return {
                ...state,
                newPhotoQueued: action.payload,
            };

        case 'SET_DEFERRED_PROMPT':
            return {
                ...state,
                deferredPrompt: action.payload,
            };

        case 'SET_PC_SKIP':
            return {
                ...state,
                pcSkip: action.payload,
            };

        case 'SET_DRIVER_LOGIN':
            return {
                ...state,
                driverLogin: true,
            };

        case 'SET_PAY_REDIRECT':
            return {
                ...state,
                paymentResponse: action.payload
            };

        case 'SET_API_SERVICE':
            return {
                ...state,
                apiService: action.payload,
            };

        case 'SET_PARKING_INFO':
            return {
                ...state,
                parkingInfo: action.payload,
            };

        case 'SET_LANDBOT_REF':
            return {
                ...state,
                landbotRef: action.payload,
            };

        case 'SET_SELECTED_CAR':
            sessionStorage.setItem("selectedCar", JSON.stringify(action.payload));
            return {
                ...state,
                selectedCar: action.payload,
            };

        case 'SET_SEARCH_PARAMETERS':
            sessionStorage.setItem("searchParameters", JSON.stringify(action.payload));
            return {
                ...state,
                searchParameters: action.payload,
            };

        case 'SET_DISCOUNT_COUPON':
            sessionStorage.setItem("coupon", JSON.stringify(action.payload));
            return {
                ...state,
                coupon: action.payload,
            };

        case 'CLEAR_SELECTIONS':
            sessionStorage.removeItem("coupon");
            sessionStorage.removeItem("customer");
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("services");
            sessionStorage.removeItem("page");
            sessionStorage.removeItem("time");
            sessionStorage.removeItem("selectedCar");
            sessionStorage.removeItem("searchParameters");
            sessionStorage.removeItem("reservationCreated");
            return {
                ...state,
                selectedCar: null,
                searchParameters: {},
            };

        case 'SHOW_MINILEASING':
            //sessionStorage.setItem("showMinileasing",action.payload);
            return {
                ...state,
                showMinileasing: action.payload,
            };

        case 'SHOW_LANDBOT_BUTTON':
            return {
                ...state,
                showLandbotButton: action.payload,
            };

        case 'SET_UNPAID_BALANCE':
            return {
                ...state,
                unpaidBalance: action.payload,
            }

        case 'SET_SMALL_AD_CONTENT':
            return {
                ...state,
                smallAdContent: action.payload,
            }

        case 'SET_LANDBOT_NOT_ALLOWED':
            return {
                ...state,
                landBotNotAllowed: action.payload,
            }

        default:
            return state;
    }
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

// We only want this on localhost
// const middleware: any = window.location.hostname.toString().indexOf('24rent.fi') === -1 ? applyMiddleware(logger) : null;
const middleware: any = null;

const combinedReducer = combineReducers({
    chauffeurReducer: mainReducer,
});



export const store = createStore(combinedReducer, compose(
    sentryReduxEnhancer, composeWithDevTools(middleware)) as any);
