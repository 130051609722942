import * as React from "react";
import Loadable from "react-loadable";
import { Button } from "react-bootstrap";
import queryString from "query-string";
import { translateService } from "./services/TranslateService";

interface LoadingComponentProps {
  isLoading: boolean;
  pastDelay: boolean;
  timedOut: boolean;
  error: any;
}

const LoadingComponent = (lc: LoadingComponentProps) => {
  const doRetry = () => (window.location.href = window.location.href + "");

  if (lc.error) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
      console.error(lc.error);

    return (
      <div>
        <div>Error!</div>
        <div className="btnDiv">
          <Button onClick={doRetry}>Retry</Button>
        </div>
      </div>
    );
  } else if (lc.timedOut) {
    return (
      <div>
        <div>Taking a long time...</div>
        <div className="btnDiv">
          <Button onClick={doRetry}>Retry</Button>
        </div>
      </div>
    );
  } else if (lc.pastDelay) {
    return (
      <div className="main-loader">
        <svg
          className="loader-spinner"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
        >
          <circle cx="25" cy="25" r="20" />
        </svg>
        <p>Loading Reservation...</p>
      </div>
    );
  } else {
    return null;
  }
};

export const AsyncMakeReservation = Loadable({
  loader: () => import("./components/RootContainer/RootContainer"),
  render(loaded, props: any) {
    const Component = loaded.default;
    const urlParams = new URLSearchParams(window.location.search);
    const hash = urlParams.get("h") || "";
    const customerId = urlParams.get("c") || "";
    return (
      <Component
        content="makereservation"
        title={translateService.translate("rootContainer.reservations")} //makereservation
        hash={hash}
        customer_id={parseInt(customerId)}
      />
    );
  },
  loading: LoadingComponent,
  delay: 300, // 0.3 seconds
  timeout: 10000,
});

export const AsyncReceipt = Loadable({
  loader: () => import("./components/RootContainer/RootContainer"),
  render(loaded, props: any) {
    const Component = loaded.default;
    const urlParams = new URLSearchParams(window.location.search);
    const hash = urlParams.get("h") || undefined;
    const customer_id = urlParams.get("c") || "";
    const language = urlParams.get("lang") ?? sessionStorage.getItem('language');
    let resId = window.location.pathname.slice(1);
    resId = resId.slice(resId.indexOf('/') + 1)

    return (
      <Component
        content="receipt"
        hash={hash}
        customer_id={parseInt(customer_id)}
        reservation_id={parseInt(resId)}
        urlLanguage={language !== null ? language : undefined}
        title={`${translateService.translate('receipt.receipt')} #${parseInt(resId)}`}
      />
    );
  },
  loading: LoadingComponent,
  delay: 300, // 0.3 seconds
  timeout: 10000,
});

// export const AsyncProfile = Loadable({
//   loader: () => import("./components/RootContainer/RootContainer"),
//   render(loaded, props) {
//     const urlParams = new URLSearchParams(window.location.search);
//     const nordeaError = urlParams.get("error") || undefined;
//     const nordeaCallback = urlParams.get("verification_callback") || undefined;
//     const Component = loaded.default;
//     let page = window.location.pathname.slice(1);
//     page = page.slice(page.indexOf('/') + 1)

//     return (
//       <Component
//         profilePage={parseInt(page)}
//         nordeaError={nordeaError}
//         nordeaCallback={nordeaCallback}
//         content="profile"
//         title={translateService.translate("rootContainer.profile")}
//       />
//     );
//   },
//   loading: LoadingComponent,
//   delay: 300, // 0.3 seconds
//   timeout: 10000,
// });

export const AsyncDeposit = Loadable({
  loader: () => import("./components/RootContainer/RootContainer"),
  render(loaded, props) {
    const Component = loaded.default;
    return (
      <Component
        content="deposit"
        title={translateService.translate("rootContainer.deposit")}
      />
    );
  },
  loading: LoadingComponent,
  delay: 300, // 0.3 seconds
  timeout: 10000,
});

export const AsyncUnpaidBalance = Loadable({
  loader: () => import("./components/RootContainer/RootContainer"),
  render(loaded, props) {
    const Component = loaded.default;
    return (
      <Component
        content="unpaidBalance"
        title={translateService.translate("rootContainer.unpaidBalance")}
      />
    );
  },
  loading: LoadingComponent,
  delay: 300, // 0.3 seconds
  timeout: 10000,
});

export const AsyncPaymentScreen = Loadable({
  loader: () => import("./components/RootContainer/RootContainer"),
  render(loaded, props) {
    const Component = loaded.default;
    const searchParams = new URLSearchParams(document.location.search);
    let loc = window.location.pathname.slice(1);
    const paymentId = loc.slice(loc.indexOf('/') + 1).split('/')[0];
    const paymentStatus = searchParams.get("checkout-status") ?? undefined;

    return (
      <Component
        content="paymentscreen"
        title={translateService.translate("rootContainer.payment")}
        paymentId={paymentId}
        paymentStatus={paymentStatus}
        queryStringValues={[queryString.parse(window.location.search)]} // #TODO: Resolves error but, NEED TO TEST THIS SOLUTION --> Bruce, thoughts?
      />
    );
  },
  loading: LoadingComponent,
  delay: 300, // 0.3 seconds
  timeout: 10000,
});

export const AsyncMyInformation = Loadable({
  loader: () => import("./components/RootContainer/RootContainer"),
  render(loaded, props) {
    const Component = loaded.default;
    return (
        <Component
            content="myInformation"
            title={translateService.translate("rootContainer.myInformation")}
        />
    );
  },
  loading: LoadingComponent,
  delay: 300, // 0.3 seconds
  timeout: 10000,
});
